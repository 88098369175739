import React from 'react';

import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { ProductDetailView } from 'components';
import { useBox } from 'boxenStopHooks';

// Constant
import { itemTypes } from 'constants/itemTypes';

const BoxItemDetail = ({ params }) => {
  const { data: boxData } = useBox(params.boxId);

  const box = boxData?.box?.box;

  return (
    <>
      <ProductDetailView box={box} itemType={itemTypes.BOX} />
    </>
  );
};

export default injectIntl(BoxItemDetail);
